<template>
  <b-modal id="modal-forward" centered class="modal-default" @show="updateDirectives">
    <div class="modal-default-itens">
      <div class="modal-default-itens-header">
        <button class="modal-default-itens-close" @click="$bvModal.hide('modal-forward')">
          <p>X</p>
        </button>
      </div>
	  <div class="mask" id="maskForward"></div>
      <div class="modal-default-itens-body">
        <div class="modal-default--header">
			<img src="@/assets/img/logo-es.png" alt="" width="264">
          <h4 class="v-content-forward" v-contentForward="{section: 'Modal Adelante', removeHtml: true}">¿Vamos juntos?</h4>
        </div>
        <div class="modal-default--text">
          <p class="v-content-forward" v-contentForward="{section: 'Modal Adelante'}">Somos <span>+ de 3600 empleados + 30 marcas + 50,000 Clientes. </span>
			  En Delta, cuando nos propusimos lograr una meta <span>somos siempre +</span>.
              En cada rincón de una tierra, ciudad o país, Delta es una tarjeta de bienvenida que marca un territorio.
              Los clientes se convierten en amigos, consumidores apasionados y nosotros, embajadores de nuestra Marca.
          </p>
          <h2 class="v-content-forward" v-contentForward="{section: 'Modal Adelante'}">¿Conquistemos + territorio, juntos?</h2>
          <p class="v-content-forward" v-contentForward="{section: 'Modal Adelante'}">
            Descubre más sobre este proyecto y conquista + territorio en:
            <span class="d-block">+ CLIENTES + AMIGOS</span>
          </p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import store from '@/store';
import axios from 'axios';
const allContents = [];
export default {
	data() {
	return {
	}
	},
	computed: {
		...mapState('App', ['loading']),
	},
	methods: {
		updateDirectives() {
			const allElements = document.querySelectorAll('.v-content-forward');
			allContents.splice(0, allContents.length); // clear the contents array
			allElements.forEach((el) => {
				const params = el.dataset.params ? JSON.parse(el.dataset.params) : {};
				params.content = el.innerHTML.trim();
				(!('type' in params) ? params.type = 'text' : null);
				params.theme = process.env.VUE_APP_THEME;
				params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
				allContents.push(params);
			});
		},
	},
	directives: {
		contentForward: {
			inserted: async function (el, binding) {
				const allElements = document.querySelectorAll('.v-content-forward');
				let params = binding.value ? binding.value : {};
				params.content = el.innerHTML.trim();
				(!('type' in params) ? params.type = 'text' : null);
				params.theme = process.env.VUE_APP_THEME;
				params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
				allContents.push(params);

				if (allElements.length == allContents.length) {
					store.dispatch('App/loading', true);
					await axios({
						method: "POST",
						url: '/api/v1/site/get-all-contents',
						data: { allContents }
					})
						.then(response => response.data)
						.then((data) => {

							const allElements = document.querySelectorAll('.v-content-forward');
							allContents.forEach((el, index) => {
								if (el.type == 'text') {
									allElements.forEach((el, index) => {
										el.innerHTML = data.data[index];
									});
								}
							});
							store.dispatch('App/loading', false);
							document.getElementById('maskForward').remove();
						});
				}
			},

		}
	},
}
</script>

<style lang="scss" scoped>
  @import '../index.scss';

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  top: 0;
  left: 0;
}
</style>
