<template>
  <section ref="home" class="content-home" v-show="!loading">

    <div class="content-home-banner">
      <div class="content-home-banner--bg"></div>
      <div class="content-home-banner-box">
        <div class="content-home-banner-box--text">
          <h1 class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">+ clientes <span>+
              amigos</span></h1>
        </div>
        <button class="btn--red d-lg-none" v-scroll-to="'#form-opportunity'">
          <p>Enviar oportunidad</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9">
            <path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)" />
          </svg>
        </button>
      </div>
      <div class="container position-relative d-none d-lg-block">
        <button class="btn-read-more" v-scroll-to="'#form-opportunity'">
          <div class="btn-read-more--text">
            <p>mas informaciones</p>
          </div>
          <div class="btn-read-more--icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11">
              <path d="M6.5,0,13,11H0Z" transform="translate(13 11) rotate(180)" fill="#fff" />
            </svg>
          </div>
        </button>
      </div>
    </div>
    <div class="content-home-what">
      <div class="container">
        <div class="content-home-what--title">
          <h2 class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">¿Qué es?</h2>
        </div>
        <div class="content-home-what--text">
          <p class="v-content-home" v-contentHome="{ section: 'Home' }">Lorem ipsum dolor sit amet, consectetur adipiscing
            elit.
            Integer pretium
            commodo accumsan. In vitae ipsum et ligula cursus semper at non dolor. Sed efficitur dapibus quam nec dictum.
            Duis varius odio non tellus accumsan, vitae convallis libero fringilla. In tempus lorem augue, id elementum
            orci pulvinar in. Cras maximus, ante nec condimentum pellentesque, orci turpis sollicitudin arcu, sed pulvinar
            nulla elit ut nunc. Suspendisse pellentesque sit amet nulla a aliquam. Etiam fermentum elit cursus, aliquet
            ante in, iaculis sapien.</p>
          <span class="v-content-home" v-contentHome="{ section: 'Home' }" @click="$bvModal.show('modal-forward')">Adelante</span>
        </div>
      </div>
    </div>
    <div class="content-home-works">
      <div class="container">
        <div class="content-home-works--title">
          <h2 class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">¿Como funciona?</h2>
        </div>
        <div class="content-home-works--text">
          <p class="v-content-home" v-contentHome="{ section: 'Home' }">Lorem ipsum dolor sit amet, consectetur adipiscing
            elit.
            Integer pretium
            commodo accumsan. In vitae ipsum et ligula cursus semper at non dolor. Sed efficitur dapibus quam nec dictum.
            Duis varius odio non tellus accumsan, vitae convallis libero fringilla.</p>
        </div>
        <div class="content-home-works-timeline">
          <div class="content-timeline">
            <div class="content-timeline--icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="65.265" height="58.026" viewBox="0 0 65.265 58.026">
                <g transform="translate(-554.404 -1290.329)">
                  <path
                    d="M33.172,57.482a1.922,1.922,0,0,1-.476-1.825l1.7-6.721a4.549,4.549,0,0,1,.952-1.779L55.9,26.712a4.542,4.542,0,0,1,6.415,0l1.621,1.632a4.521,4.521,0,0,1,0,6.415L43.395,55.295a3.644,3.644,0,0,1-1.689.952l-6.811,1.711a1.851,1.851,0,0,1-1.723-.476Zm1.281-1.292,6.811-1.7a1.818,1.818,0,0,0,.85-.476L58.3,37.819l-5.474-5.474-16.2,16.2a1.825,1.825,0,0,0-.476.839Zm28.2-26.565-1.632-1.632a2.729,2.729,0,0,0-3.842,0L54.1,31.064l5.474,5.474,3.071-3.06a2.722,2.722,0,0,0,0-3.853ZM41.321,54.717l.385,1.53Z"
                    transform="translate(554.404 1290.329)" fill="#f41f2b" />
                  <path
                    d="M7.253,56.213h21.76a3.711,3.711,0,0,0,.317,1.813H7.253A7.256,7.256,0,0,1,0,50.773V7.253A7.253,7.253,0,0,1,7.253,0H23.131a5.461,5.461,0,0,1,3.853,1.593L41.921,16.535a5.466,5.466,0,0,1,1.6,3.853V33.954l-1.813,1.813V20.388c0-.147-.011-.295-.023-.442H28.106a4.532,4.532,0,0,1-4.533-4.533V1.841a3.615,3.615,0,0,0-.442-.027H7.253a5.44,5.44,0,0,0-5.44,5.44V50.773a5.441,5.441,0,0,0,5.44,5.44ZM40.924,18.133a2.449,2.449,0,0,0-.283-.306L25.692,2.875c-.091-.1-.295-.192-.306-.277V15.413a2.713,2.713,0,0,0,2.72,2.72Z"
                    transform="translate(554.404 1290.329)" fill="#151515" />
                </g>
              </svg>
            </div>
            <div class="content-timeline--text">
              <p class="v-content-home content-timeline--text--title" v-contentHome="{ section: 'Home', removeHtml: true }">Registrar Oportunidad</p>
              <p class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">Después de enviar un
                oportunidad de
                el negocio será
                generado
                una pista para uno de nuestros comerciales.</p>
            </div>
          </div>
          <div class="content-timeline">
            <div class="content-timeline--icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="67.169" height="53.735" viewBox="0 0 67.169 53.735">
                <g transform="translate(-792.81 -1294.62)">
                  <path
                    d="M24,8.4H19.236a4.543,4.543,0,0,0-4.544,4.534.84.84,0,0,0,1.679,0,2.867,2.867,0,0,1,2.865-2.855H24a3.27,3.27,0,0,1,1.457,6.2L21.44,18.356a.85.85,0,0,0-.451.745V21.83a.84.84,0,0,0,1.679,0V19.615l3.554-1.84a5.106,5.106,0,0,0,2.743-4.426A4.965,4.965,0,0,0,24,8.4ZM21.828,25.671a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,21.828,25.671Zm21.83-7.2C43.658,8.27,33.887,0,21.828,0S0,8.27,0,18.471A16.628,16.628,0,0,0,4.372,29.533,28.663,28.663,0,0,1,.217,35.587a.807.807,0,0,0-.151.877.792.792,0,0,0,.737.479A21.786,21.786,0,0,0,10.85,34.42a24.859,24.859,0,0,0,10.978,2.523C33.887,36.943,43.658,28.673,43.658,18.471ZM21.828,35.264a23.214,23.214,0,0,1-10.234-2.35l-.765-.379-.75.388a20.46,20.46,0,0,1-7.32,2.234,29.865,29.865,0,0,0,3.116-4.882l.508-1.015-.753-.85a15.038,15.038,0,0,1-3.951-9.939c0-9.259,9.039-16.792,20.151-16.792S41.979,9.213,41.979,18.471,32.942,35.264,21.828,35.264Z"
                    transform="translate(792.812 1294.62)" fill="#151515" />
                  <path
                    d="M62.927,46.567a17.439,17.439,0,0,0,4.24-11.3c0-9.4-7.567-17.138-17.569-18.3a.836.836,0,0,0-.193,1.661c9.061,1.068,16.082,8.121,16.082,16.643a15.668,15.668,0,0,1-3.843,10.221l-.726.857.515,1a30.491,30.491,0,0,0,2.979,4.614A20.385,20.385,0,0,1,57.5,49.92l-.753-.37L56,49.93a21.233,21.233,0,0,1-8.983,2.126c-7.722,0-14.336-4.338-17.086-10.468A.826.826,0,0,0,29,41.119l-.038.009a.824.824,0,0,0-.566,1.145C31.4,48.991,38.6,53.735,47.016,53.735a21.5,21.5,0,0,0,9.745-2.307,21.648,21.648,0,0,0,9.6,2.307.793.793,0,0,0,.737-.483.807.807,0,0,0-.151-.877A29.332,29.332,0,0,1,62.927,46.567ZM41.213,36.386a.839.839,0,0,0,0,1.187l4.58,4.58a.839.839,0,0,0,.594.246,1.071,1.071,0,0,0,.607-.259l9.618-10.075A.84.84,0,0,0,55.4,30.9l-9.024,9.454L42.4,36.387A.844.844,0,0,0,41.213,36.386Z"
                    transform="translate(792.812 1294.62)" fill="#f41f2b" />
                </g>
              </svg>
            </div>
            <div class="content-timeline--text">
              <p class="v-content-home content-timeline--text--title" v-contentHome="{ section: 'Home', removeHtml: true }">Se contacta al cliente</p>
              <p class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">O cliente será contactado
                para
                avaliar viabilidade da
                oportunidade registada.</p>
            </div>
          </div>
          <div class="content-timeline">
            <div class="content-timeline--icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="65.276" height="58.026" viewBox="0 0 65.276 58.026">
                <g transform="translate(-1037.178 -1290.329)">
                  <path
                    d="M42.613,48.959a.907.907,0,0,0-.907.907v.907a5.44,5.44,0,0,1-5.44,5.44H7.253a5.44,5.44,0,0,1-5.44-5.44V7.253a5.44,5.44,0,0,1,5.44-5.44h16.32v13.6a4.533,4.533,0,0,0,4.533,4.533H43.213a.3.3,0,0,0,.306-.306,3.627,3.627,0,0,0-1.062-2.565L26.452,1.062A3.643,3.643,0,0,0,23.879,0H7.245A7.246,7.246,0,0,0,0,7.253V50.773a7.247,7.247,0,0,0,7.245,7.253h29.02a7.253,7.253,0,0,0,7.253-7.253v-.907A.909.909,0,0,0,42.613,48.959ZM25.386,2.565,40.958,18.133H28.106a2.728,2.728,0,0,1-2.72-2.72Z"
                    transform="translate(1037.178 1290.329)" fill="#151515" />
                  <path
                    d="M64.282,16.138l-4.257-4.257a3.4,3.4,0,0,0-4.812,0l-24,24a1.135,1.135,0,0,0-.31.579l-1.877,8.873H27.574l-2.72-2.72a1.852,1.852,0,0,0-2.564,0l-2.028,2.03-3.176-7.622a1.885,1.885,0,0,0-3.35,0l-3.461,8.312H8.16a.907.907,0,0,0,0,1.813h2.72a.906.906,0,0,0,.838-.56l3.7-8.87,3.7,8.872a.907.907,0,0,0,1.479.294l2.985-2.989,2.985,2.985a.878.878,0,0,0,.641.267s3.091-.008,3.168-.023L39.7,45.258a1.137,1.137,0,0,0,.579-.31l24-24A3.4,3.4,0,0,0,64.282,16.138ZM39.145,43.519l-8.13,1.638,1.627-8.14L50.514,19.145l6.5,6.5ZM63,19.663l-4.709,4.708-6.5-6.5L56.5,13.16a1.59,1.59,0,0,1,2.247,0L63,17.417A1.583,1.583,0,0,1,63,19.663Z"
                    transform="translate(1037.178 1290.329)" fill="#f41f2b" />
                </g>
              </svg>
            </div>
            <div class="content-timeline--text">
              <p class="v-content-home content-timeline--text--title" v-contentHome="{ section: 'Home', removeHtml: true }">Criada a proposta</p>
              <p class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">A proposta poderá ser
                concretizada ou
                não. Receberás
                por
                email o resultado da oportunidade registada.</p>
            </div>
          </div>
          <div class="content-timeline">
            <div class="content-timeline--icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="73.02" height="58.314" viewBox="0 0 73.02 58.314">
                <g transform="translate(-1285 -1289.989)">
                  <path
                    d="M38.358,14.5a6.532,6.532,0,0,1,5.089,2.6l5.842,7.6,5.842-7.6a6.367,6.367,0,0,1,11.5,3.788,6.02,6.02,0,0,1-1.917,4.564h2.83a5.545,5.545,0,0,1,5.476,5.476V52.832a5.413,5.413,0,0,1-5.476,5.476H31.033a5.413,5.413,0,0,1-5.476-5.476V30.926a5.545,5.545,0,0,1,5.476-5.476h2.83a6.02,6.02,0,0,1-1.917-4.564A6.473,6.473,0,0,1,38.358,14.5ZM42,18.216a4.689,4.689,0,0,0-3.64-1.894,4.642,4.642,0,0,0-4.587,4.564,4.5,4.5,0,0,0,4.564,4.564h9.31ZM27.382,30.926v9.127H48.376V27.275H31.033A3.714,3.714,0,0,0,27.382,30.926Zm0,21.906a3.583,3.583,0,0,0,3.651,3.651H48.376v-14.6H27.382ZM71.194,41.879H50.2v14.6H67.543a3.583,3.583,0,0,0,3.651-3.651ZM50.2,40.053H71.194V30.926a3.714,3.714,0,0,0-3.651-3.651H50.2Zm10.04-14.6a4.5,4.5,0,0,0,4.564-4.564,4.538,4.538,0,0,0-8.226-2.67l-5.648,7.234Z"
                    transform="translate(1285 1289.995)" fill="#f41f2b" />
                  <path
                    d="M16.452,9.536l3.936-9a.91.91,0,1,1,1.666.731L18.438,9.555,27.816,3.78a.912.912,0,0,1,1.255.3,1.018,1.018,0,0,1-.3,1.256l-9.116,5.51H28.3a.988.988,0,0,1,.913.913.856.856,0,0,1-.913.913H8.215A6.458,6.458,0,0,0,1.825,19.06V50.093a6.326,6.326,0,0,0,6.389,6.389H20.993a.99.99,0,0,1,.913.913.856.856,0,0,1-.913.913H8.215A8.148,8.148,0,0,1,0,50.093V19.06a8.279,8.279,0,0,1,8.215-8.215H13.2L4.086,5.335A.913.913,0,0,1,5.042,3.78L14.5,9.6,11.024,1.258a.913.913,0,1,1,1.686-.7Z"
                    transform="translate(1285 1289.995)" fill="#151515" />
                </g>
              </svg>
            </div>
            <div class="content-timeline--text">
              <p class="v-content-home content-timeline--text--title" v-contentHome="{ section: 'Home', removeHtml: true }">Premiação</p>
              <p class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">Se a oportunidade que
                identificaste
                se converteu numa
                venda, podes usufruir de uma comissão que podes reverter em compras na nossa loja.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-home-opportunity" id="form-opportunity">
      <div class="container">
        <div class="content-home-opportunity--title">
          <h2 class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">Nova <span>oportunidade</span>
          </h2>
          <h3 class="v-content-home" v-contentHome="{ section: 'Home', removeHtml: true }">*campos de preenchimento
            obrigatório
          </h3>
        </div>
        <form ref="formLead" @submit.prevent="onLead" class="content-home-opportunity-form">
          <div class="row">
            <div class="col-12">
              <div class="content-input-select">
                <select v-model="selectedOption">
                  <option value="" selected>Tipo de oportunidad*</option>
                  <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
                </select>
                <span v-if="errors.type_id" v-bind:class="{ 'has-error': errors.type_id }">{{ errors.type_id }}</span>
                <input type="hidden" name="type_id" :value="selectedOption">
                <input type="hidden" name="campaign_id" :value="campaigns[0]?.id" required>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8">
                  <path d="M4.5,0,9,8H0Z" transform="translate(9 8) rotate(180)" fill="#f41f2b" />
                </svg>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" v-model="name_company" name="name_company" value=""
                  placeholder="Nombre del establecimiento*" />
                <span v-if="errors.name_company" v-bind:class="{ 'has-error': errors.name_company }">{{
                  errors.name_company }}</span>
              </div>
            </div>
            <!-- <div class="col-lg-6">
              <div class="content-input">
                <input type="text" value="" name="postal_code" placeholder="Código Postal*" required/>
              </div>
            </div> -->
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" v-model="locality" value="" name="locality"
                  placeholder="Ubicación de la propiedad*" />
                <span v-if="errors.locality" v-bind:class="{ 'has-error': errors.locality }">{{ errors.locality }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="content-input">
                <input type="text" v-model="name" name="name" value="" placeholder="Nombre de contacto*" />
                <span v-if="errors.name" v-bind:class="{ 'has-error': errors.name }">{{ errors.name }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" name="email" v-model="email" placeholder="E-mail" />
                <span v-if="errors.email" v-bind:class="{ 'has-error': errors.email }">{{ errors.email }}</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <input type="text" name="phone" v-model="phone" placeholder="Teléfono*" maxlength="9" />
                <span v-if="errors.phone" v-bind:class="{ 'has-error': errors.phone }">{{ errors.phone }}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="content-input">
                <span v-if="errors.message" v-bind:class="{ 'has-error': errors.message }">{{ errors.message }}</span>
                <textarea id="message" name="message" v-model="message" maxlength="240"></textarea>
                <span class="d-flex justify-content-end">{{ messageLength }}/240 caracteres.</span>
              </div>
            </div>
            <div class="col-12">
              <button type="submit" class="btn--black">
                <p>Anotar</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9">
                  <path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)" />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <ModalForward/>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import store from '@/store';
import axios from 'axios';
import ModalForward from '@/components/Modals/ModalForward';

const allContents = [];
export default {
  data() {
    return {
      campaigns: [],
      selectedOption: '',
      name_company: '',
      locality: '',
      name: '',
      options: [
        { value: 1, label: 'Nuevo cliente' },
        { value: 2, label: 'Cliente existente' },
      ],
      message: '',
      messageLength: 0,
      email: '',
      phone: '',
      errors: {
        type_id: '',
        name_company: '',
        address: '',
        name: '',
        email: '',
        phone: '',
        message: ''

      }
    }
  },
  components: {
    ModalForward
  },
  computed: {
    ...mapState('App', ['loading']),
    formattedMessage() {
      return this.message.substring(0, 240);
    }
  },
  methods: {
    //...mapActions('App', ['loading']),
    async listCampaigns() {
      await axios({
        method: 'GET',
        url: '/api/v1/campaign/list-all',
        params: {
          is_active: 1
        }
      }).then(response => response.data.data)
        .then((data) => {
          this.campaigns = data;
        })
    },
    async onLead(el) {
      this.formValidate();

      if (this.errors.email || this.errors.phone || this.errors.type_id || this.errors.name_company || this.errors.locality || this.errors.name || this.errors.email || this.errors.phone || this.errors.message) {
        this.$scrollTo('#form-opportunity', 500, { easing: 'ease-in-out' });
        return;
      }

      let formData = new FormData(this.$refs.formLead)

      store.dispatch('App/loading', true);
      await axios({
        method: 'POST',
        url: '/api/v1/campaign/lead',
        data: formData
      }).then(response => response.data)
        .then((data) => {
          store.dispatch('App/loading', false);
          this.$notify({
            type: "success",
            text: data.message,
            duration: 10000
          });
        })
        .catch((error) => {
          store.dispatch('App/loading', false);
          this.$notify({
            type: "warn",
            text: error.response.data.message,
            duration: 10000
          });
        })
        this.$refs.formLead.reset();
    },
    formValidate() {
      this.validateEmail();
      this.validatePhone();
      this.validateType();
      this.validateNameCompany();
      this.validateLocality();
      this.validateName();
      this.validateMessage();
    },
    validateEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!this.email) {
        this.errors.email = ''
      } else if (!regex.test(this.email)) {
        this.errors.email = 'Insira um e-mail válido'
      } else {
        this.errors.email = ''
      }
    },
    validatePhone() {
      const regex = /^\d{9}$/;
      if (!this.phone || !regex.test(this.phone)) {
        this.errors.phone = 'Por favor ingrese un número de teléfono válido. (9 dígitos)'
      } else {
        this.errors.phone = ''
      }
    },
    validateType() {
      if (!this.selectedOption) {
        this.errors.type_id = 'Ingrese un tipo de oportunidad'
      } else {
        this.errors.type_id = ''
      }
    },
    validateNameCompany() {
      if (!this.name_company) {
        this.errors.name_company = 'Introduzca un nombre de establecimiento'
      } else {
        this.errors.name_company = ''
      }
    },
    validateLocality() {
      if (!this.locality) {
        this.errors.locality = 'Ingrese una ubicación'
      } else {
        this.errors.locality = ''
      }
    },
    validateName() {
      if (!this.name) {
        this.errors.name = 'Ingrese un nombre'
      } else {
        this.errors.name = ''
      }
    },
    validateMessage() {
      if (!this.message) {
        this.errors.message = 'Ingrese un mensaje'
      } else {
        this.errors.message = ''
      }
    },
    updateDirectives() {
			const allElements = document.querySelectorAll('.v-content-home');
			allContents.splice(0, allContents.length); // clear the contents array
			allElements.forEach((el) => {
				const params = el.dataset.params ? JSON.parse(el.dataset.params) : {};
				params.content = el.innerHTML.trim();
				(!('type' in params) ? params.type = 'text' : null);
				params.theme = process.env.VUE_APP_THEME;
				params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
				allContents.push(params);
			});
		},
  },
  created() {
    this.updateDirectives();
    this.$bvModal.show('modal-forward');
  },

  async mounted() {
    this.$bvModal.show('modal-forward');
    await this.listCampaigns();
    if(localStorage.getItem('scrollToElement')) {
      setTimeout(() => {
        this.$scrollTo('#form-opportunity', 800, { easing: 'ease-in-out' });
        localStorage.removeItem('scrollToElement');
      }, 2000); 
    }

  
  },
  directives: {
    contentHome: {
      inserted: async function (el, binding) {
        const allElements = document.querySelectorAll('.v-content-home');
        let params = binding.value ? binding.value : {};
        params.content = el.innerHTML.trim();
        (!('type' in params) ? params.type = 'text' : null);
        params.theme = process.env.VUE_APP_THEME;
        params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
        allContents.push(params);

        if (allElements.length == allContents.length) {
          store.dispatch('App/loading', true);
          await axios({
            method: "POST",
            url: '/api/v1/site/get-all-contents',
            data: { allContents }
          })
            .then(response => response.data)
            .then((data) => {

              const allElements = document.querySelectorAll('.v-content-home');
              allContents.forEach((el, index) => {
                if (el.type == 'text') {
                  allElements.forEach((el, index) => {
                    el.innerHTML = data.data[index];
                  });
                }
              });
              store.dispatch('App/loading', false);
            });
        }
      },

    }
  },
  watch: {
    email: function (email) {
      this.emailValid = this.validateEmail(email);
    },
    phone: function (phone) {
      this.phoneValid = this.validatePhone(phone);
    },
    selectedOption: function (selectedOption) {
      this.typeValid = this.validateType(selectedOption);
    },
    name_company: function (name_company) {
      this.nameCompanyValid = this.validateNameCompany(name_company);
    },
    locality: function (locality) {
      this.localityValid = this.validateLocality(locality);
    },
    name: function (name) {
      this.nameValid = this.validateName(name);
    },
    message() {
      this.messageLength = this.message.length;
    }
  }
}
</script>
<style lang="scss">@import './index.scss';</style>
